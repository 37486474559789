import React, {useState} from 'react'
import styles from './styles/contact.module.scss'
import Layout from "../components/Layout"
import {TextField, Button, Snackbar, SnackbarContent } from '@material-ui/core'
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import HeaderBack from "../components/HeaderBack"
import PartnerSection from "../components/PartnerSection"
import FreeTour from './../components/FreeTour'
import config from './../config'
import SEO from "../components/seo"
import {IoIosWarning, IoIosCheckmarkCircle} from "react-icons/io";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(theme => ({
	root: {
	  flexGrow: 1,
	},
	paper: {
	  padding: theme.spacing(2),
	  textAlign: 'center',
	  color: theme.palette.text.secondary,
	},
  }));


const Contact = () => {
	const classes = useStyles();
	const [userContact, setUserContact] = useState({
		'name': '',
		'lastname': '',
		'email': '',
		'phone': '',
		'message': '',
	})
	const [openSnack, setOpenSnack] = useState(false)
	const [errorMessage, setErrorMessage] = useState({
		'message': '',
		'color': ''
	})

	const handleContact = (event,name) => {
		setUserContact({...userContact, [name]: event.target.value});
	}

	const handleSubmit = async() => {
		if(!validateValues(userContact)){
			try{
				const response = await fetch(`${config.server}/contact`,{
					method: 'POST',
					body: JSON.stringify({
						name: userContact.name,
						lastname: userContact.lastname,
						email: userContact.email,
						phone: userContact.phone,
						message: userContact.message,
					}),
					headers: {
						'Content-Type': 'application/json'
					}
				})
				const statusRequest = await response.json()
				if(statusRequest.state == "SUCCESS"){
					setUserContact({
						'name': '',
						'lastname': '',
						'email': '',
						'phone': '',
						'message': '',
					})
					setErrorMessage({'message':'Mensaje enviado correctamente, nuestros administradores se pondran en contacto contigo.','color':'#43a047'})
					setOpenSnack(true)
				}
			}catch(e){
				console.log(e)
			}
		}
	}

	const validateValues = (userContact) => {
		let flag = false
		let valuesData = Object.values(userContact)

		for(let i = 0; i < valuesData.length; i++){
			if(/^\s*$/.test(valuesData[i])){
				flag = true;
				setErrorMessage({'message':'Por favor completa el formulario.','color':'#d32f2f'});
				setOpenSnack(true);
			}
		}

		if(flag) return flag;

		if(!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(userContact.email)){
			setErrorMessage({'message':'Email invalido.','color':'#d32f2f'})
			setOpenSnack(true)
			flag = true
		}

		return flag
	}

	const SnackBarWrapper = ({message}) => {
		return(
			<Snackbar
				anchorOrigin={{
				  vertical: 'bottom',
				  horizontal: 'left',
				}}
				open={openSnack}
				autoHideDuration={6000}
				onClose={()=>setOpenSnack(!openSnack)}
			  >
				<SnackbarContent
					style={{backgroundColor: errorMessage.color}}
					  message={
						<span className={styles.errorMessage}>
							{
								errorMessage.color == "#d32f2f" ? (
									<IoIosWarning size={20} className={styles.iconError}/>
								): (
									errorMessage.color == "#43a047" ? (
										<IoIosCheckmarkCircle size={20} className={styles.iconError}/>
									): (
										<p>Nada</p>
									)
								)
							}
						    <span>{message}</span>
						</span>
					  }
					  onClose={()=>setOpenSnack(!openSnack)}
					  variant="error"
				/>
			</Snackbar>
		)
	}

	return(
		<Layout>
			<SEO title="Contacto" />
			<HeaderBack
				title="Consultar Espacios"
				description="Haz parte de la comunidad Cowork entre todos. Llena la siguiente informacion."
				image={require('./../images/ingresar.jpg')}
			/>

			<div className={classes.root}>
				<Grid container spacing={3}>
					<Grid item xs={12}
					style = {{
						justifyContent: "center",
						display: "flex",
						marginTop: "1rem"
					}}>
						<h2>¿Deseas hacer una consulta? Escríbenos un mensaje.</h2>
					</Grid>
					
					{/* <ThemeProvider theme={theme}>
					 <Grid item xs={6}>
						<TextField
					        label="Nombre"
					        className={styles.textField}
					        margin="normal"
							type="text"
					        variant="outlined"
							value={userContact.name}
							onChange={(e) => handleContact(e, 'name')}
				        />
						</Grid>

						<Grid item xs={6}>
						<TextField
					        label="Apellidos"
					        className={styles.textField}
					        margin="normal"
							type="text"
							value={userContact.lastname}
							onChange={(e) => handleContact(e, 'lastname')}
					        variant="outlined"
					    />
						</Grid>
						<TextField
							label="Email"
							className={styles.textField}
							margin="normal"
							type="email"
							value={userContact.email}
							onChange={(e) => handleContact(e, 'email')}
							variant="outlined"
						/>
						<TextField
							label="Telefono"
							className={styles.textField}
							margin="normal"
							type="number"
							value={userContact.phone}
							onChange={(e) => handleContact(e, 'phone')}
							variant="outlined"
						/>
						<TextField
							label="Mensaje"
							className={styles.textFieldLarge}
							margin="normal"
							type="text"
							value={userContact.message}
							onChange={(e) => handleContact(e, 'message')}
							variant="outlined"
							multiline
        					rowsMax="10"
							rows="10"
						/>
					</ThemeProvider> */}
				</Grid>
				</div>

			<div className={styles.mainForm}>
				{/* <h2>¿Deseas hacer una consulta? Escribenos un mensaje.</h2> */}
				<div className={styles.inputContainer}>
					<ThemeProvider theme={theme}>
					<Grid container spacing={3}>
					<Grid item xs={12} md = {6}>
						<TextField
					        label="Nombre"
					        className={styles.textField}
					        margin="normal"
							type="text"
					        variant="outlined"
							value={userContact.name}
							onChange={(e) => handleContact(e, 'name')}
				        />
						</Grid>
						<Grid item xs={12} md = {6}>
						<TextField
					        label="Apellidos"
					        className={styles.textField}
					        margin="normal"
							type="text"
							value={userContact.lastname}
							onChange={(e) => handleContact(e, 'lastname')}
					        variant="outlined"
					    />
						</Grid>
						<Grid item xs={12} md = {6}>
						<TextField
							label="Email"
							className={styles.textField}
							margin="normal"
							type="email"
							value={userContact.email}
							onChange={(e) => handleContact(e, 'email')}
							variant="outlined"
						/>
						</Grid>
						<Grid item xs={12} md = {6}>
						<TextField
							label="Telefono"
							className={styles.textField}
							margin="normal"
							type="number"
							value={userContact.phone}
							onChange={(e) => handleContact(e, 'phone')}
							variant="outlined"
						/>
						</Grid>
						</Grid>
						<Grid container spacing={3}
						style = {{
							display:"flex",
							justifyContent: "center"
						}}>
						<Grid item xs={12}>
						<TextField
							label="Mensaje"
							className={styles.textFieldLarge}
							margin="normal"
							type="text"
							value={userContact.message}
							onChange={(e) => handleContact(e, 'message')}
							variant="outlined"
							multiline
        					rowsMax="10"
							rows="10"
						/>
						</Grid>
						<Grid item xs={10}>
							<Button onClick={handleSubmit} className={styles.buttonLogin} variant="contained">EVIAR CONSULTA</Button>
						</Grid>
						</Grid>
					</ThemeProvider>
					<Grid container spacing={3}>
						
					</Grid>
				</div>
			</div>
			<SnackBarWrapper message={errorMessage.message}/>
		</Layout>
	)
}


const theme = createMuiTheme({
	overrides: {
      MuiOutlinedInput: {
          root: {
              position: 'relative',
			  color:'#000',
              '& $notchedOutline': {
                  borderColor: 'rgba(0,0,0,0.23)',
              },
              '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
                  	borderColor: 'rgba(0,0,0,0.23)',
                  // Reset on touch devices, it doesn't add specificity
                  '@media (hover: none)': {
                    borderColor: 'rgba(0,0,0,0.23)',
                  },
              },
              '&$focused $notchedOutline': {
                  borderColor: '#DE892A',
                  borderWidth: 1,
              },
          },
      },
      MuiFormLabel: {
          root: {
			  color:'rgba(0,0,0,0.54)',
              '&$focused': {
                  color: '#DE892A'
              }
          }
      }}
});

export default Contact
